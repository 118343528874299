<template>
  <div>
    <b-container fluid>
      <b-row align-h="center">
        <div class="text-center mt-4 h5">
          <div>
            {{ $t("powerFee.pvInfo") }}
          </div>
          <div class="mt-1">
             ({{ $t("powerFee.connectTime") }}: 2019/12/05 13:32:50)
          </div>
        </div>
      </b-row>
      <downloadBtn class="mb-4 d-flex justify-content-center" />
      <b-row>
        <b-table :items="tableGroup" striped hover thead-class="g-class"></b-table>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import { mapState, mapMutations } from 'vuex'
import downloadBtn from '../../components/DownloadButton'
export default {
  name: 'PowerFee',
  components: {
    downloadBtn
  },
  data () {
    return {

    }
  },
  computed: {
    ...mapState('pvStringFailure', [
      'tableGroup'
    ])
  },
  methods: {
    ...mapMutations('pvStringFailure', [
      'SET_GROUP_TABLE'
    ])
  },
  mounted () {
    this.SET_GROUP_TABLE()
  }
}
</script>
<style lang="scss">
  @import '../../assets/scss/customVariables.scss';
  .g-class{
    background: $acme-blue-3 !important;
  }
  .table-striped tbody tr:nth-of-type(odd) {
    background-color: $acme-blue-8;
  }
</style>
